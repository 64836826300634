<template>

  <main class="contact">

    <div class="container">
      <div class="row">
        <div class="col s12">

          <router-link to="/" title="Radio Kraków Kultura" class="breadcrumb">
            Radio Kraków Kultura
          </router-link>

          <router-link to="/zespol" title="Zespół" class="breadcrumb">
            Zespół
          </router-link>
        </div>
      </div>

      <div class="row">
        <div class="col s12">
          <h1 class="big-title">Zespół</h1>
        </div>
      </div>
      <div class="row" :class="{loading: stateCompare('loading')}">
        <div class="col s12 m6 l4 xl3" v-for="user in users">
          <PeopleLine :user="user"/>
        </div>
      </div>

    </div>

  </main>

  <SectionInfo code="people"/>
</template>

<script>
import PeopleLine from "@/views/People/Part/Line.vue";
import CoreArray from "@/helpers/array.js";
import axios from "@/axios2";
import ComponentListStateMachine from "../../Extends/ComponentListStateMachine";
import SectionInfo from "../Home/SectionInfo";

export default {
  name: "PeopleList",
  extends: ComponentListStateMachine,
  components: {SectionInfo, PeopleLine},
  data() {
    return {
      'count': null,
      'users': null

    }
  },
  methods: {
    refresh: function () {
      this.stateUpdate('sendInfo');

      axios
          .get('user/get', {
            params: {
              sort: ['priority DESC','lastname ASC'],
              page: this.page,
              show_on_list: "yes",
              role: "editor",
              scope: 'firstname,lastname,avatar_url,url_full,position'
            }
          })
          .then(response => {

            let arr = response.data.data;
            let column = "avatar_url";
            // let from = "https://cms.radiokrakowkultura.pl/app/";
            // let to = "https://cms.radiokrakowkultura.pl/";

            // arr = CoreArray.changeDomain(arr,column,from,to);

            arr = CoreArray.changeDomain(arr,'url_full','ludzie-radia/','zespol/');

            this.users = arr;
            this.count = response.data.count;

            this.stateUpdate('sendSuccess');
          })
          .catch(error => {

            this.stateUpdate('sendWrong');
            // this.refresh();

          });

    }
  },
  watch: {
    page: function (val) {
      this.refresh();
    },
  },
  created() {
    this.refresh();
  }
}
</script>

<style scoped>

</style>